<template>
  <div class="container">
    <h5 class="mt-3">ข้อมูล{{name }}</h5>
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddOffice"
        >
          <i class="fa fa-plus"></i> เพิ่ม{{ name }}
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col" v-if="master_id =='character' && 'transporttypes'">ประเภทรถ</th>
          <th scope="col" v-if="master_id != 'taxcycle'">{{name}}</th>
          <th scope="col" v-if="master_id == 'taxcycle'">เดือน</th>
          <th scope="col" v-if="master_id == 'taxcycle'">งวด</th>
          <th scope="col" v-if="master_id =='transporttypes'">หมวด</th>
          <th scope="col">สถานะ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td v-if="master_id =='character' && 'transporttypes'">
            {{ l.type }}
          </td>
          <td v-if="master_id == 'axewheeltire'">{{ l.axe }} เพลา {{ l.wheel }} ล้อ {{ l.tire }} เส้น</td>
          <td v-if="master_id != 'axewheeltire' && master_id != 'taxcycle'">{{l.name}}</td>
          <td scope="col" v-if="master_id == 'taxcycle'">{{getround(l.month)}}</td>
          <td scope="col" v-if="master_id == 'taxcycle'">{{l.round}}</td>

          <td v-if="master_id =='transporttypes'">
            {{ l.category }}
          </td>
          <td>
            <label v-if="l.status == 1">ใช้งานอยู่</label>
            <label v-else>ไม่ใช้งาน</label>
          </td>
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddOffice"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a :href="'/maintenanceitems/'+l.id" v-if="master_id =='maintenance'">
              <button
                type="button"
                class="btn btn-info"
              >
                <i class="fa fa-eye"></i></button
            ></a>
            &nbsp;
            <a @click="deletedata(l.id,0)" v-if="l.status == 1">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-trash"></i></button
            ></a>
            <a @click="deletedata(l.id,1)" v-else>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-repeat"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddOffice"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                
                <div class="form-group mt-3" v-if="master_id =='taxcycle'">
                  <label for="username">เดือน</label>
                  <select class="form-control form-control-sm" v-model="data.month">
            <option v-for="(i, r) in months" :key="r" :value="i.month">{{ i.name }}</option>
          </select>
                </div>
                <div class="form-group mt-3" v-if="master_id =='character' || master_id ==  'transporttypes'">
                  <label for="username">ประเภทรถ</label>
                  <select class="form-control form-control-sm" v-model="data.type_id">
            <option v-for="(i, r) in types" :key="r" :value="i.id">{{ i.name }}</option>
          </select>
                </div>
                <div class="row" v-if="master_id =='axewheeltire'">
                  <div class="col-md-4">
                <div class="input-group input-group-sm mt-3" >
                  <input
                    v-model="data.axe"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">เพลา</span>
                </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-group input-group-sm mt-3" >
                  <input
                    v-model="data.wheel"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">ล้อ</span>
                </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-group input-group-sm mt-3" >
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">ยาง</span>
                </div>
                  <input
                    v-model="data.tire"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">เส้น</span>
                </div>
                </div>
              </div>
              </div>
              <div class="form-group mt-3" v-if="master_id =='taxcycle'">
                <label for="username">{{ name }}</label>
                  <input
                    v-model="data.round"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
              </div>
                <div class="form-group mt-3" v-if="master_id !='axewheeltire' && master_id !='taxcycle'">
                  <label for="username">{{ name }}</label>
                  <input
                    v-model="data.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mt-3" v-if="master_id == 'transporttypes'">
                  <label for="username">หมวด</label>
                  <input
                    v-model="data.category"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteStatus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="typestatus == 0">ยืนยันการลบ{{ name }}</h5>
            <h5 class="modal-title" id="exampleModalLabel" v-else>ยืนยันการกู้คืน{{ name }}</h5>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger btn-sm" @click="deletebyid()">
              <label v-if="typestatus == 0">ลบ{{ name }}</label>
              <label v-else>กู้คืน{{ name }}</label>
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypesService from "../services/TypesService";
import MasterService from "../services/MasterService";
import CharacterService from "../services/CharacterService";
import BrandsService from "../services/BrandsService";
import MaintenanceService from "../services/MaintenanceService";
import ColorsService from "../services/ColorsService";
import AxeService from "../services/AxeService";
import PositionCarService from "../services/PositionCarService";
import PositionMachineService from "../services/PositionMachineService";
import RoleCarService from "../services/RoleCarService";
import TransportTypeService from "../services/TransportTypeService";
import BrandsMachineService from "../services/BrandsMachineService";
import TaxCycleService from "../services/TaxCycleService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      data: {},
      data_id: 0,
      title: "",
      name:'',
      master_id:0,
      types:[],
      typestatus:1,
      months :[
  { name: "มกราคม", month: 1 },
  { name: "กุมภาพันธ์", month: 2 },
  { name: "มีนาคม", month: 3 },
  { name: "เมษายน", month: 4 },
  { name: "พฤษภาคม", month: 5 },
  { name: "มิถุนายน", month: 6 },
  { name: "กรกฎาคม", month: 7 },
  { name: "สิงหาคม", month: 8 },
  { name: "กันยายน", month: 9 },
  { name: "ตุลาคม", month: 10 },
  { name: "พฤศจิกายน", month: 11 },
  { name: "ธันวาคม", month: 12 },
]
    };
  },
  mounted() {
    // console.log(this.$route.params);
    MasterService.getmaster(this.$route.params.idmain).then((res)=>{
      // console.log(res.data);
      this.name = res.data.name
    })
    this.master_id = this.$route.params.id;
    this.getdata()
    // console.log(this.currentUser);
    
    if (this.master_id == 'tablemaintenance') {
      this.$router.push('/tablemaintenance');
    }
  },
  methods: {
    getround(m){
const result = this.months.find(({ month }) => month === m);

// console.log(result); // { name: 'cherries', quantity: 5 }
      if (m.toString().length == 1) {
      m = "0" + m;
    }
      const d = new Date("2022-"+m+"-01");
      var lastDayOfMonth = new Date(d.getFullYear(), d.getMonth()+1, 0);
      var day = lastDayOfMonth.getDate()
      // console.log(day);
     var r = day +' '+result.name 
return r;
    },
    getdata(){
      if (this.master_id == 'types') {
      TypesService.gettypess('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
      else if (this.master_id == 'character') {
        CharacterService.getcharacters('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
        TypesService.gettypess('').then((res)=>{
          // console.log(res.data);
          this.types = res.data
        })
      })
    }
    else if (this.master_id == 'brand') {
      BrandsService.getbrands('').then((res)=>{
        console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'maintenance') {
      MaintenanceService.getmaintenances('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'colors') {
      ColorsService.getcolors('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'axewheeltire') {
      AxeService.getaxewheeltires('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'positioncar') {
      PositionCarService.getposition_cars('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'positionmachine') {
      PositionMachineService.getposition_machines('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'rolecar') {
      RoleCarService.getrole_cars('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'transporttypes') {
      TransportTypeService.gettransport_types('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
        TypesService.gettypess('').then((res)=>{
          // console.log(res.data);
          this.types = res.data
        })
      })
    }
    else if (this.master_id == 'brandmachine') {
      BrandsMachineService.getbrandmachines().then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    else if (this.master_id == 'taxcycle') {
      TaxCycleService.getTaxCycles('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    }
    },
    getid(id) {
      this.data_id = id;
      if (this.data_id != 0) {
        this.title = "แก้ไขข้อมูล"+this.name;
        // console.log(this.data_id);
        if (this.$route.params.id == 'types') {
      TypesService.gettypes(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }else if (this.$route.params.id == 'character') {
      CharacterService.getcharacter(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }else if (this.$route.params.id == 'brand') {
      BrandsService.getbrand(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'maintenance') {
      MaintenanceService.getmaintenance(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'colors') {
      ColorsService.getcolor(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'axewheeltire') {
      AxeService.getaxewheeltire(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'positioncar') {
      PositionCarService.getposition_car(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'positionmachine') {
      PositionMachineService.getposition_machine(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'rolecar') {
      RoleCarService.getrole_car(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'transporttypes') {
      TransportTypeService.gettransporttype(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    else if (this.$route.params.id == 'brandmachine') {
      BrandsMachineService.getbrandmachine(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
    
    else if (this.$route.params.id == 'taxcycle') {
      TaxCycleService.getTaxCycle(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
    }
      } else {
        this.title = "เพิ่มข้อมูล"+this.name;
        this.data = {};
      }
    },
    deletedata(id,typestatus){
      this.data_id = id;
      this.typestatus = typestatus
    },
    deletebyid(){
      if (this.master_id == 'types') {
      TypesService.deletetypes(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'character') {
      CharacterService.deletecharacter(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'brand') {
      BrandsService.deletebrand(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'maintenance') {
      MaintenanceService.deletemaintenance(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'colors') {
      ColorsService.deletecolor(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'axewheeltire') {
      AxeService.deleteaxewheeltire(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'positioncar') {
      PositionCarService.deleteposition_car(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'positionmachine') {
      PositionMachineService.deleteposition_machine(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'rolecar') {
      RoleCarService.deleterole_car(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'transporttypes') {
      TransportTypeService.deletetransporttype(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'brandmachine') {
      BrandsMachineService.deletebrandmachine(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    else if (this.master_id == 'taxcycle') {
      TaxCycleService.deleteTaxCycle(this.data_id,this.typestatus).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
    }
    },
    save() {
      if (this.$route.params.id == 'types') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datatype = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      TypesService.createtypes(datatype).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      TypesService.updatetypes(this.data_id,datatype).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'character') {
      if (this.data.type_id == "" || this.data.type_id == null ) {
        alert("กรุณาเลือกประเภทรถ");
      } else if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      }else {
        var datacharacter = {
          name: this.data.name,
          type_id:this.data.type_id,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      CharacterService.createcharacter(datacharacter).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      CharacterService.updatecharacter(this.data_id,datacharacter).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'brand') {
       if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      }else {
        var databrand = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      BrandsService.createbrand(databrand).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      BrandsService.updatebrand(this.data_id,databrand).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'maintenance') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datamain = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      MaintenanceService.createmaintenance(datamain).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      MaintenanceService.updatemaintenance(this.data_id,datamain).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'colors') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datacolor = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      ColorsService.createcolor(datacolor).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      ColorsService.updatecolor(this.data_id,datacolor).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'axewheeltire') {
        if (this.data.axe == "" || this.data.axe == null ) {
        alert("กรุณากรอกจำนวนเพลา");
      } else if (this.data.wheel == "" || this.data.wheel == null ) {
        alert("กรุณากรอกจำนวนล้อ");
      } 
      else if (this.data.tire == "" || this.data.tire == null ) {
        alert("กรุณากรอกจำนวนยาง");
      } else {
        var dataaxe = {
          axe: this.data.axe,
          wheel:this.data.wheel,
          tire:this.data.tire,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      AxeService.createaxewheeltire(dataaxe).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      AxeService.updateaxewheeltire(this.data_id,dataaxe).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }else if (this.$route.params.id == 'positioncar') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datapositioncar = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      PositionCarService.createposition_car(datapositioncar).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      PositionCarService.updateposition_car(this.data_id,datapositioncar).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    else if (this.$route.params.id == 'positionmachine') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datapositionmachine = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      PositionMachineService.createposition_machine(datapositionmachine).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      PositionMachineService.updateposition_machine(this.data_id,datapositionmachine).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    else if (this.$route.params.id == 'rolecar') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datarole = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      RoleCarService.createrole_car(datarole).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      RoleCarService.updaterole_car(this.data_id,datarole).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    else if (this.$route.params.id == 'transporttypes') {
        if (this.data.type_id == "" || this.data.type_id == null ) {
        alert("กรุณาเลือกประเภท");
      }else if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datatran = {
          type_id:this.data.type_id,
          name: this.data.name,
          category: this.data.category,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      TransportTypeService.createtransporttype(datatran).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      TransportTypeService.updatetransporttype(this.data_id,datatran).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    else if (this.$route.params.id == 'brandmachine') {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var databrandmac = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      BrandsMachineService.createbrandmachine(databrandmac).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      BrandsMachineService.updatebrandmachine(this.data_id,databrandmac).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    
    else if (this.$route.params.id == 'taxcycle') {
        if (this.data.month == "" || this.data.month == null ) {
        alert("กรุณาเลือกเดือน");
      }  else if (this.data.round == "" || this.data.round == null ) {
        alert("กรุณากรอก"+this.name);
      } else {
        var datatax = {
          month: this.data.month,
          round: this.data.round,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
      TaxCycleService.createTaxCycle(datatax).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      TaxCycleService.updateTaxCycle(this.data_id,datatax).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
      }
      }
    }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
