<template>
  <div class="container">
    <div class="card-body mt-3">
      <!-- <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputEmail4">วัน เดือน ปีที่ครอบครอง</label>
          <input id="date" class="form-control" />
        </div>
      </div> -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputEmail4">ผู้ประกอบการขนส่ง <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputEmail4" v-model="user.name">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputPassword4">หนังสือสำคัญแสดงการจดทะเบียน/บัตรประจำตัวเลขที่ <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.idcard">
        </div>
        <div class="form-group col-md-6">
          <label for="inputPassword4">สัญชาติ</label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.national">
        </div>
      </div>
      <div class="form-group">
        <label for="inputAddress2">ที่อยู่</label>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="inputAddress">บ้านเลขที่ <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputAddress" v-model="user.number">
        </div>
        <div class="form-group col-md-2">
          <label for="inputAddress">หมู่ที่</label>
          <input type="text" class="form-control form-control-sm" id="inputAddress" v-model="user.village_no">
        </div>
        <div class="form-group col-md-2">
          <label for="inputAddress">ซอย</label>
          <input type="text" class="form-control form-control-sm" id="inputAddress" v-model="user.lane">
        </div>
        <div class="form-group col-md-2">
          <label for="inputAddress">ถนน</label>
          <input type="text" class="form-control form-control-sm" id="inputAddress" v-model="user.road">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">จังหวัด <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" @change="onChangeProvince"
            v-model="user.province_id">
            <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">อำเภอ <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" @change="onChangeAmphur" v-model="user.amphur_id">
            <option v-for="(a, i) in amphurs" :key="i" :value="a.id"> {{ a.name_th }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">ตำบล <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" @change="onChangeDistrict"
            v-model="user.district_id">
            <option v-for="(d, i) in districts" :key="i" :value="d.id"> {{ d.name_th }}</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="inputZip">รหัสไปรษณีย์</label>
          <input type="text" class="form-control form-control-sm" id="inputZip" v-model="zipcode">
        </div>
        <div class="form-group col-md-3">
          <label for="inputZip">โทร</label>
          <input type="text" class="form-control form-control-sm" id="inputZip" v-model="user.phone">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputState">ประเภทประกอบการขนส่ง <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.transporttype_id">
            <option v-for="(t, i) in transporttypes" :key="i" :value="t.id"> {{ t.type }}&nbsp;{{ t.name }}&nbsp;<span v-if="t.category">หมวด {{ t.category }}</span></option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="inputPassword4">ใบอนุญาตเลขที่ <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.license_number">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputEmail4">วันสิ้นอายุใบอนุญาต <span style="color:red">*</span></label>
          <input id="expire_date" class="form-control" />
        </div>
        </div>
      <button class="btn btn-success" style="color: white; width: 200px" @click="save()">
        บันทึก
      </button>
    </div>
  </div>
</template>
<script src="https://eva-transport.visdchiangrai.com/picker_date.js"></script>

<script>
import ProvinceService from "../services/ProvinceService";
import AmphuresService from "../services/AmphuresService";
import DistrictService from "../services/DistrictService";
import AddressService from "../services/AddressService";
import MemberService from "../services/MemberService";
import TransportTypeService from "../services/TransportTypeService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[],
      provinces:[],
      amphurs:[],
      districts:[],
      zipcode:'',
      own_id:0,
      transporttypes:[]
    };
  },
  mounted() {
    this.own_id = this.$route.params.id;
    this.getprovinces()
    if (this.own_id != 0) {
    this.getOwnership();
    }
    // var date = new Date()
    // var y = date.getUTCFullYear() +543
    // y = y-2566
    // console.log(y);
    picker_date(document.getElementById("expire_date"), { year_range: "-20:+"+10 });
    TransportTypeService.gettransport_types(1).then((res)=>{
this.transporttypes = res.data
    })
  },
  methods: {
    createddate(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
    onChangeProvince(){
// console.log(evt.target.value);
this.getamphurs()
this.user.amphur_id = null
        this.user.district_id = null
    },
    onChangeAmphur(){
// console.log(evt.target.value);
this.getdistricts()
    },
    onChangeDistrict(){
// console.log(evt.target.value);
this.getzipcode()
    },
    getprovinces(){
      ProvinceService.getprovinces().then((res)=>{
        this.provinces = res.data
        // console.log(res.data);
      })
    },
    getamphurs(){
      AmphuresService.getamphures(this.user.province_id).then((res)=>{
        this.amphurs = res.data
        this.districts = {}
        this.zipcode = ''
        // console.log(res.data);
      })
    },
    getdistricts(){
      DistrictService.getdistricts(this.user.amphur_id).then((res)=>{
        this.districts = res.data
        // console.log(res.data);

        this.getzipcode()
      })
    },
    getzipcode(){
      DistrictService.getdistrict(this.user.district_id).then((res)=>{
        this.zipcode = res.data.zip_code
        // console.log(res.data);
      })
    },
    save() {
      if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกผู้ประกอบการขนส่ง");
      } else if (this.user.idcard == "" || this.user.idcard == null) {
        alert("กรุณากรอกหนังสือสำคัญแสดงการจดทะเบียน/บัตรประจำตัวเลขที่");
      }else if (this.user.transporttype_id == "" || this.user.transporttype_id == null) {
        alert("กรุณาเลือกประเภทประกอบการขนส่ง");
      } else if (document.getElementById("expire_date").value == "" || document.getElementById("expire_date").value == null) {
        alert("กรุณากรอกวันสิ้นอายุใบอนุญาต ");
      }else if (this.user.number == "" || this.user.number == null) {
        alert("กรุณากรอกบ้านเลขที่");
      }
      else if (this.user.province_id == "" || this.user.province_id == null) {
        alert("กรุณาเลือกจังหวัด");
      }else if (this.user.amphur_id == "" || this.user.amphur_id ==  null) {
        alert("กรุณาเลือกอำเภอ");
      }else if (this.user.district_id == "" || this.user.district_id == null)  {
        alert("กรุณาเลือกตำบล");
      } 
      // else if (this.user.phone == "" || this.user.phone == null) {
      //   alert("กรุณากรอกเบอร์โทรศัพท์");
      // } 
      else {
        // console.log(userdata);
        var address ={
              number:this.user.number,
              village_no:this.user.village_no,
              lane:this.user.lane,
              road:this.user.road,
              amphur_id:this.user.amphur_id,
              district_id:this.user.district_id,
              province_id:this.user.province_id,
              created_by:this.currentUser.id
            }
        if (this.own_id == 0) {
          
            AddressService.createaddress(address).then((res)=>{

        var userdata = {
          name: this.user.name,
          idcard: this.user.idcard,
          national: this.user.national,
          expire_date: this.createddate(document.getElementById("expire_date").value),
          phone: this.user.phone,
          transporttype_id: this.user.transporttype_id,
          license_number: this.user.license_number,
          created_by:this.currentUser.id,
          address_id:res.data.id,
          status:1
        };
          MemberService.createmember(userdata).then(() => {
            // console.log(res.data);
            // console.log(res.data.id);
            
              // console.log(res.data);
            alert('บันทึกสำเร็จ')
            this.$router.push('/members');
            })
          });
        } else {
          var owner = {
            name: this.user.name,
          idcard: this.user.idcard,
          national: this.user.national,
          expire_date: this.createddate(document.getElementById("expire_date").value),
          phone: this.user.phone,
          transporttype_id: this.user.transporttype_id,
          license_number: this.user.license_number,
        };
            // console.log(owner);
            MemberService.updatemember(this.own_id, owner).then(() => {
            // console.log(res.data);
            console.log(address);
            AddressService.updateaddress(this.user.address_id,address).then(() => {
              alert('บันทึกสำเร็จ')
            this.$router.push('/members');
            });
          });
        }
      }
    },
    getdate(value){
      // console.log(value); 
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()+543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day + "/" + month + "/" + year;
    },
    getOwnership() {
      MemberService.getmember(this.own_id).then((res) => {
        this.user = res.data;
        this.getamphurs()
        this.getdistricts()
        // console.log(res.data);
        document.getElementById("expire_date").value = this.getdate(this.user.expire_date)
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}</style>
