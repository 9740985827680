import Vue from "vue";
import Router from "vue-router";
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import qrcode from './components/qrcode.vue';
import addqrcode from './components/addqrcode.vue';
import evaluation from './components/evaluation.vue';
import header from './components/header.vue';
import Office from './components/Office.vue';
import evaluationall from './components/evaluationall.vue';
import Menumanage from './components/Menumanage.vue';
import report from './components/report.vue';
import master from './components/master.vue';
import masteredit from './components/masteredit.vue';
import maintenanceitems from './components/maintenanceitems.vue';
import maintenancelist from './components/maintenancelist.vue';
import maintenance from './components/maintenance.vue';
import tablemaintenance from './components/tablemaintenance.vue';
import ownership from './components/ownership.vue';
import ownershipedit from './components/ownershipedit.vue';
import members from './components/members.vue';
import memberedit from './components/memberedit.vue';
import owners from './components/owners.vue';
import owneredit from './components/owneredit.vue';
import license from './components/license.vue';
import licenseedit from './components/licenseedit.vue';
import vehicleedit from './components/vehicleedit.vue';
import vehicles from './components/vehicles.vue';

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: Login
    },
{
  path: "/user",
  alias: "/user",
  name: "user",
  component: Users       
},
{
  path: "/menu",
  alias: "/menu",
  name: "menu",
  component: Menu       
},
{
  path: "/qrcode",
  alias: "/qrcode",
  name: "qrcode",
  component: qrcode       
},
{
  path: "/addqrcode/:type/:id",
  alias: "/addqrcode",
  name: "addqrcode",
  component: addqrcode       
},
{
  path: "/evaluation/:type/:id",
  alias: "/evaluation",
  name: "evaluation",
  component: evaluation       
},
{
  path: "/header",
  alias: "/header",
  name: "header",
  component: header       
},
{
  path: "/office",
  alias: "/office",
  name: "office",
  component: Office       
},
{
  path: "/evaluationall",
  alias: "/evaluationall",
  name: "evaluationall",
  component: evaluationall       
},
{
  path: "/Menumanage",
  alias: "/Menumanage",
  name: "Menumanage",
  component: Menumanage       
},
{
  path: "/report",
  alias: "/report",
  name: "report",
  component: report       
},
{
  path: "/master",
  alias: "/master",
  name: "master",
  component: master       
},
{
  path: "/master/:id/:idmain",
  alias: "/master",
  name: "master",
  component: masteredit       
},
{
  path: "/maintenanceitems/:id",
  alias: "/maintenanceitems",
  name: "maintenanceitems",
  component: maintenanceitems       
},
{
  path: "/maintenancelist/:id",
  alias: "/maintenancelist",
  name: "maintenancelist",
  component: maintenancelist       
},
{
  path: "/maintenance",
  alias: "/maintenance",
  name: "maintenance",
  component: maintenance       
},
{
  path: "/tablemaintenance",
  alias: "/tablemaintenance",
  name: "tablemaintenance",
  component: tablemaintenance       
},
{
  path: "/ownerships",
  alias: "/ownerships",
  name: "ownerships",
  component: ownership       
},
{
  path: "/ownership/:id",
  alias: "/ownership",
  name: "ownership",
  component: ownershipedit       
},
{
  path: "/members",
  alias: "/members",
  name: "members",
  component: members       
},
{
  path: "/member/:id",
  alias: "/member",
  name: "member",
  component: memberedit       
},
{
  path: "/owners",
  alias: "/owners",
  name: "owners",
  component: owners       
},
{
  path: "/owner/:id",
  alias: "/owner",
  name: "owner",
  component: owneredit       
},
{
  path: "/licenses",
  alias: "/licenses",
  name: "licenses",
  component: license       
},
{
  path: "/license/:id",
  alias: "/license",
  name: "license",
  component: licenseedit       
},
{
path: "/vehicles",
alias: "/vehicles",
name: "vehicles",
component: vehicles       
},
{
path: "/vehicle/:id",
alias: "/vehicle",
name: "vehicle",
component: vehicleedit       
},
  ]
});

Vue.use(Router);

router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  var qrcode = ''
  if (page[1] == 'evaluation') {
    qrcode = to.path
  }
  // console.log(page[1]);
  // console.log(qrcode);
  const publicPages = ['/','/login',qrcode];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userjrc');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) { 
    next('qrcode');
  } else {
    next();
  }

});

export default router;
