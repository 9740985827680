<template>
  <div class="container-fluid">
    <div class="card-body mt-3">
      <h5 style="text-align:center">รายการจดทะเบียน</h5>
      <!-- {{ user }} -->
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="exampleFormControlInput1">เลขทะเบียน</label>
          <input v-model="license" type="text" class="form-control form-control-sm" id="exampleFormControlInput1" v-on:keyup="search"/>
          <div v-if="license != '' && filteredList.length > 0">
            <ul class="list-group" v-for="(s, i) in filteredList" v-bind:value="s.id" :key="i + 1">
              <div class="
                      form-control form-control-sm
                      d-flex
                      justify-content-between
                      align-items-center
                    " style="border-color: blue">
                <a @click="getlicense(s)">
                  {{ s.registration_no }}</a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">วันที่จดทะเบียน &nbsp; </label>
          <label for="inputState">{{ convertdatetothai(user.registration_date) }} </label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">เลขทะเบียน &nbsp; </label>
          <label for="inputState">{{ user.registration_no }}</label>

        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">จังหวัด &nbsp;</label>
          <label for="inputPassword4">{{ user.province }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">รหัสตรวจสภาพ&nbsp;</label>
          <label for="inputPassword4">{{ user.inspection_code || '-' }} </label>
        </div>
        <div class="form-group col-md-4">

        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">ประเภท &nbsp;</label>
          <label for="inputPassword4">{{ user.type }} {{ user.tran }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ลักษณะ/มาตรฐาน &nbsp;</label>
          <label for="inputPassword4">{{ user.character_name }} </label>
        </div>
        <div class="form-group col-md-4">

        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">ยี่ห้อรถ &nbsp;</label>
          <label for="inputPassword4">{{ user.brand }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">แบบ/รุ่น&nbsp;</label>
          <label for="inputPassword4">{{ user.model }} </label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">สี &nbsp;</label>
          <label for="inputPassword4">{{ user.color }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">เลขตัวรถ &nbsp;</label>
          <label for="inputPassword4">{{ user.car_number }} </label>
        </div>
        <div class="form-group col-md-4">

        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">อยู่ที่&nbsp;</label>
          <label for="inputPassword4">{{ user.position_car }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ยี่ห้อเครื่องยนต์&nbsp;</label>
          <label for="inputPassword4">{{ user.brand_machine }} </label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">เลขเครื่องยนต์&nbsp;</label>
          <label for="inputPassword4">{{ user.no_machine }} </label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">อยู่ที่ &nbsp;</label>
          <label for="inputPassword4">{{ user.position_machine }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">จำนวน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <label for="inputPassword4">{{ user.no }} &nbsp;</label>
          <label for="inputPassword4">สูบ</label>
          <label for="inputPassword4">{{ user.horsepower }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">แรงม้า&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <label for="inputPassword4">{{ user.kilowatt }} &nbsp;</label>
          <label for="inputPassword4">กิโลวัตต์&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">{{ user.axe }} เพลา {{ user.wheel }} ล้อ ยาง {{ user.tire }} เส้น</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักรถ&nbsp;&nbsp;</label>
          <label for="inputPassword4">{{ user.weight }} กก.</label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">จำนวนผู้โดยสาร&nbsp;&nbsp;&nbsp;</label>
          <label for="inputPassword4">{{ user.no_passenger }}&nbsp; คน</label>
          <label for="inputPassword4">&nbsp;&nbsp;&nbsp;ยืน {{ user.stand }} &nbsp;คน</label>
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักบรรทุก/น้ำหนักลงเพลา&nbsp;</label>
          <label for="inputPassword4">{{ user.payload }} กก.</label>

        </div>
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักรวม&nbsp;</label>
          <label for="inputPassword4">{{ user.weight_sum }} กก.</label>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">วันที่สิ้นอายุภาษี &nbsp;</label>
          <label for="inputPassword4">{{ convertdatetothai(user.expire_date) }}</label>
        </div>
      </div>
      <hr />
      <h5 style="text-align:center">เจ้าของรถ</h5>
      <div class="form-row">
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">วัน เดือน ปีที่ครอบครอง<span style="color:red">*</span></label>
          <input id="date" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ผู้ประกอบการขนส่ง <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" v-model="user.member_id" @change="onChangeMember">
            <option v-for="(l, i) in members" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">หนังสือสำคัญแสดงการจดทะเบียน/บัตรประจำตัวเลขที่ &nbsp;</label>
          <label for="inputState">{{ member.idcard }} </label>
        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">สัญชาติ </label>
          <label for="inputState">{{ member.national }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ที่อยู่ </label>
          <span> {{ member.number }}&nbsp;</span>
          <span v-if="member.village_no">หมู่ที่ {{ member.village_no }}&nbsp;</span>
          <span v-if="member.lane">ซอย {{ member.lane }}&nbsp;</span>
          <span v-if="member.road">ถนน {{ member.road }}&nbsp;</span>
          <span>ตำบล{{ member.district }}&nbsp;</span>
          <span>อำเภอ{{ member.amphur }}&nbsp;</span>
          <span>จังหวัด{{ member.province }}&nbsp;</span>
          <span>{{ member.zip_code }}&nbsp;</span>

        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">โทร&nbsp; </label>
          <label for="inputState">{{ member.phone }} </label>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ประกอบการขนส่งประเภท &nbsp;</label>
          <label for="inputState">{{ member.type }} {{ member.tran }} {{ member.category }} </label>
        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">ใบอนุญาตเลขที่&nbsp; </label>
          <label for="inputState">{{ member.license_number }} </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">วันสิ้นอายุใบอนุญาต </label>
          <label for="inputState">{{ convertdatetothai(member.expire_date) }} </label>
        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">มีสิทธิครอบครองและใช้รถโดย <span style="color:red">*</span></label>

          <select id="inputState" class="form-control form-control-sm" v-model="user.role_car">
            <option v-for="(r, i) in rolecar" :key="i" :value="r.id"> {{ r.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ผู้ถือกรรมสิทธิ์ &nbsp;</label>
          <label for="inputPassword4">{{ user.own_name }}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ที่อยู่ <span style="color:red">*</span></label>
          <span> {{ user.number }}&nbsp;</span>
          <span v-if="user.village_no">หมู่ที่ {{ user.village_no }}&nbsp;</span>
          <span v-if="user.lane">ซอย {{ user.lane }}&nbsp;</span>
          <span v-if="user.road">ถนน {{ user.road }}&nbsp;</span>
          <span>ตำบล{{ user.district_own }}&nbsp;</span>
          <span>อำเภอ{{ user.amphure_own }}&nbsp;</span>
          <span>จังหวัด{{ user.province_own }}&nbsp;</span>
          <span>{{ user.zip_code_own }}&nbsp;</span>
        </div>
        <div class="form-group col-md-4">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">โทร&nbsp; </label>
          <label for="inputPassword4">{{ user.own_phone }}</label>
        </div>
      </div>
      <button class="btn btn-success" style="color: white; " @click="save()">
        บันทึก
      </button>
    </div>
  </div>
</template>
<script src="https://eva-transport.visdchiangrai.com/picker_date.js"></script>

<script>
import ProvinceService from "../services/ProvinceService";
import AmphuresService from "../services/AmphuresService";
import LicenseService from "../services/LicenseService";
import TransportTypeService from "../services/TransportTypeService";
import BrandsService from "../services/BrandsService";
import CharacterService from "../services/CharacterService";
import ColorsService from "../services/ColorsService";
import PositionCarService from "../services/PositionCarService";
import PositionMachineService from "../services/PositionMachineService";
import BrandsMachineService from "../services/BrandsMachineService";
import AxeService from "../services/AxeService";
import OwnerService from "../services/OwnerService";
import MemberService from "../services/MemberService";
import RoleCarService from "../services/RoleCarService";
import VehicleService from '../services/VehicleService';
import TaxCycleService from '../services/TaxCycleService';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[],
      provinces:[],
      amphurs:[],
      districts:[],
      zipcode:'',
      own_id:0,
      transporttypes:[],
      types:[],
      characters:[],
      brands:[],
      colors:[],
      position_machine:[],
      position_car:[],
      brand_machines:[],
      axes:[],
      owners:[],
      license:'',
      licenses:[],
      license_id:0,
      members:[],
      rolecar:[],
      member:{},
      vehicle_id:0,
      filteredList:[]
    };
  },
  mounted() {
    MemberService.getmembers().then((res)=>{
      this.members = res.data
    });
    RoleCarService.getrole_cars().then((res)=>{
      this.rolecar = res.data
    });
    LicenseService.getlicenses('').then((res)=>{
        this.licenses = res.data
        console.log(res.data);
      })
    this.vehicle_id = this.$route.params.id;
    this.getprovinces()
    if (this.vehicle_id != 0) {
    this.getvehicle();
    }
    // var date = new Date()
    // var y = date.getUTCFullYear() +543
    // y = y-2566
    // console.log(y);
    picker_date(document.getElementById("date"), { year_range: "-20:+"+10 });
    TransportTypeService.gettransport_types().then((res)=>{
this.transporttypes = res.data
this.getmaster()
    })
  },
  methods: {
    search(){
      LicenseService.getlicenses(this.license).then((res)=>{
        this.filteredList = res.data
        console.log(res.data);
      })
    },
    onChangeMember(){
      LicenseService.getmemberdetail(this.user.member_id).then((res)=>{
        // console.log(res.data)
        this.member = res.data
      })
    },
    getlicense(n) {
      // console.log(n);
      this.license = n.registration_no;
      this.license_id = n.id
  this.getOwnership()
    },
    getmaster(){
      TransportTypeService.gettransport_types(1).then((res)=>{
        this.types = res.data
      })
      CharacterService.getcharacters(1).then((res)=>{
        this.characters = res.data
        // console.log(res.data);
      })
      BrandsService.getbrands(1).then((res)=>{
        this.brands = res.data
      })
      ColorsService.getcolors(1).then((res)=>{
        this.colors = res.data
      })
      PositionCarService.getposition_cars(1).then((res)=>{
        this.position_car = res.data
      })
      PositionMachineService.getposition_machines(1).then((res)=>{
        this.position_machine = res.data
      })
      BrandsMachineService.getbrandmachines(1).then((res)=>{
        this.brand_machines = res.data
      })
      AxeService.getaxewheeltires(1).then((res)=>{
        this.axes = res.data
      })
      OwnerService.getowners(1).then((res)=>{
        this.owners = res.data
        // console.log(res.data);
      })
    },
    createddate(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
  gettax(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var month = date.getMonth()+1;
    
      return  month
    
  },
    onChangeProvince(){
// console.log(evt.target.value);
this.getamphurs()
    },
    onChangeAmphur(){
// console.log(evt.target.value);
this.getdistricts()
    },
    onChangeDistrict(){
// console.log(evt.target.value);
this.getzipcode()
    },
    getprovinces(){
      ProvinceService.getprovinces().then((res)=>{
        this.provinces = res.data
        // console.log(res.data);
      })
    },
    getamphurs(){
      AmphuresService.getamphures(this.user.province_id).then((res)=>{
        this.amphurs = res.data
        this.districts = {}
        this.zipcode = ''
        // console.log(res.data);
      })
    },
    save() {
      if (this.user.license_id == "" || this.user.license_id == null) {
        alert("กรุณาเลือกเลขทะเบียน");
      }else if (document.getElementById("date").value == "" || document.getElementById("date").value == null) {
        alert("กรุณากรอกวัน เดือน ปีที่ครอบครอง");
      } else if (this.user.member_id == "" || this.user.member_id == null) {
        alert("กรุณาเลือกผู้ประกอบการขนส่ง");
      }else if (this.user.role_car == "" || this.user.role_car == null) {
        alert("กรุณาเลือกสิทธิครอบครองและใช้รถ");
      }
      else {
        console.log(this.getdate(this.user.expire_date));
        var date = new Date(this.member.expire_date);
    var month = date.getMonth()+1;
        TaxCycleService.getTaxCycles(month).then((res)=>{
      var tax = 1
      if (res.data.length > 0) {
        tax =res.data[0].id
      }
      console.log(tax);
      var vehicle ={
          date: this.createddate(document.getElementById("date").value),
          license_id: this.user.license_id,
          taxcycle_id: tax,
          status:  1,
          role_car: this.user.role_car,
          created_by:this.currentUser.id,
            }
            var mem = {
              member_id:this.user.member_id
            }
            LicenseService.updatemember(this.license_id,mem).then(() => {
        if (this.vehicle_id == 0) {
          
          VehicleService.createvehicle(vehicle).then(()=>{

            alert('บันทึกสำเร็จ')
            this.$router.push('/vehicles');
            })
        } else {
            // console.log(owner);
            VehicleService.updatevehicle(this.vehicle_id, vehicle).then(() => {
            // console.log(res.data);
            
              alert('บันทึกสำเร็จ')
            this.$router.push('/vehicles');
          });
        }
        });
      
    })
        }
    },
    getdate(value){
      // console.log(value); 
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()+543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day + "/" + month + "/" + year;
    },
    getvehicle(){
      VehicleService.getvehicle(this.vehicle_id).then((res) => {
        // console.log(res.data);
        this.user = res.data;
this.onChangeMember()
document.getElementById("date").value = this.getdate(this.user.date)
this.license = this.user.registration_no
      });
    },
    getOwnership() {
      LicenseService.getlicenseitem(this.license_id).then((res) => {
        console.log(res.data);

        this.user = res.data;
  this.user.license_id = res.data.id
  this.filteredList = []
      });    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    // filteredList() {
    //   // console.log(this.licenses);
    //   return this.licenses.filter((post) => {
    //     // console.log(post.registration_no);
    //     return post.registration_no.toLowerCase().includes(this.license.toLowerCase());
    //   });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
